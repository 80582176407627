import * as React from "react";
import styles from "./styles.module.css";
import Select from "react-select";
import { optionType } from "types";
import { WarningIcon } from "assets";
import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";

interface CustomSelectProps {
  parentClassName?: string;
  inputClass?: string;
  dataTestID?: string;
  onChange: (e) => void;
  validatorMessage: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  name: string;
  placeholder: string;
  label: string;
  id?: string;
  options: optionType[];
  value: optionType;
  isMulti?: boolean;
  disabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  dataTestID,
  placeholder,
  parentClassName,
  name,
  validatorMessage,
  onChange,
  inputClass,
  label,
  id,
  options,
  value,
  isMulti,
  disabled,
}) => {
  return (
    <div className={`${styles.inputWrapper} ${parentClassName} ${validatorMessage ? styles.error : ""}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Select
        onChange={(x) => onChange(x)}
        placeholder={placeholder}
        data-testid={dataTestID}
        className={`${inputClass} ${styles.select}`}
        classNamePrefix="formSelect"
        id={id}
        name={name}
        options={options}
        value={value.value ? value : null}
        isMulti={isMulti}
        isDisabled={disabled}
      />
      {validatorMessage && (
        <small className={styles.message}>
          <WarningIcon /> {validatorMessage.toString()}
        </small>
      )}
    </div>
  );
};

const FilterBox: React.FC<CustomSelectProps> = (props) => {
  const { parentClassName, name, onChange, label, options, value, inputClass } = props;

  return (
    <div className={`${styles.inputWrapper} ${parentClassName} ${styles.filterItem}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Select
        onChange={(x) => onChange(x)}
        className={`${inputClass} ${styles.select} ${styles.filterItemInput}`}
        classNamePrefix="formSelect"
        name={name}
        options={options}
        value={value}
        menuShouldBlockScroll
        // styles={{
        //   option: (base) => ({
        //     ...base,
        //     textAlign: "left",
        //     textOverflow: "ellipsis"
        //   }),
        // }}
        menuPlacement="auto"
        menuPortalTarget={document.getElementsByTagName("body")[0]}
      />
    </div>
  );
};

export { CustomSelect, FilterBox };

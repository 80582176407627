import { boolean } from "yup";
import {
  agentVerificationRequestURL,
  applicationURL,
  deleteAgentURL,
  deleteRequest,
  deleteVerifiedAgentURL,
  getAllSupportMessageURL,
  getDelSupportMsgURL,
  getRequest,
  getUnVerifiedAgentsURL,
  getUpdateSupportMsgTypeURL,
  getVerifiedAgentsURL,
  loginURL,
  patchRequest,
  postRequest,
  SupportMessageParams,
  universityVerificationURL,
  updateVerificationStatusURL,
  verifyAgentURL,
  getSpecialOffers,
  getCommentURL,
  fileUploadURL,
  createSpecialOffer,
  addDocumentURL,
  getSingleApplicationURL,
  createCommentURL,
  editCommentURL,
  deleteCommentURL,
  updateApplicationStatusURL,
  createBlogURL,
  getAllBlogsURL,
  getSingleBlogURL,
  deleteBlogURL,
  editBlogURL,
  addInstitutionURL,
  fetchInstitutionsURL,
  fetchAllVerifiedAgentsURL,
  fetchInstitutionURL,
  fetchInstitutionAgentsURL,
  fetchInstitutionCoursesURL,
  fetchCourseURL,
  updateStatusInstitutionURL,
  updateStatusCourseURL,
  createCourseURL,
  updateStatusAgentURL,
  fetchNonAgentsURL,
  addAgentsToUniURL,
  editCourseURL,
  editInstitutionURL,
  listSignupsURL,
  getOneSignupURL,
  verifySignupURL,
  putRequest,
  fetchProspectsURL,
  viewProspectURL,
  updateProspectStatusURL,
  createProspectCommentURL,
  fetchProspectCommentURL,
  editProspectCommentURL,
  deleteProspectCommentURL,
  fetchProspectApplicationsURL,
  updateProspectDocsURL,
  fetchAgentsUnpaginatedURL,
  fetchUnisUnpaginatedURL,
  updateProspectPlanURL,
  fetchProspectsUnpaginatedURL,
  fetchCoursesUnpaginatedURL,
  fetchCountryUnpaginatedURL,
  createApplicationURL,
  editApplicationURL,
  editProspectURL,
  createProspectURL,
  fetchAgentProfileURL,
  editAgentProfileURL,
} from "../../api";
import { updateVerifiedAgentURL } from "../../api/apiUrls/verifiedAgents";
import { VerifiedAgentRoles } from "../../components/table/tableBodyVariants/VerifiedAgentsTable/index";
import { uploadDocsType } from "components";

/**
 *  =================================================
 *  LOGIN REQUEST SERVCES
 *  =================================================
 */

interface LoginData {
  email: string;
  password: string;
  captcha: string;
}

/**
 * Login  service
 * @returns axios promise
 */

export const loginRequestService = ({ data }: { data: LoginData }) => {
  const requestData = {
    url: loginURL(),
    data,
  };

  return postRequest(requestData);
};

/**
 *  =================================================
 *  VERIFIED AGENTS SERVICES
 *  =================================================
 */

/**
 * Get verified agents  service
 * @returns axios promise
 */

export const getAllVerifiedAgentsService = ({ pageNo = 1, source = "", search = "" }) => {
  const requestData = {
    url: getVerifiedAgentsURL({ pageNo, source, search }),
  };

  return getRequest(requestData);
};

/**
 * Delete verified agents  service
 * @returns axios promise
 */

export const deleteVerifiedAgentService = ({ agentId }: { agentId: string }) => {
  const requestData = {
    url: deleteVerifiedAgentURL(agentId),
  };

  return deleteRequest(requestData);
};

/**
 * Update verified agents  service
 * @returns axios promise
 */

export const updateVerifAgencyServ = ({ agentId, role }: { agentId: string; role: VerifiedAgentRoles }) => {
  const requestData = {
    url: updateVerifiedAgentURL(agentId),
    data: {
      role,
    },
  };

  return patchRequest(requestData);
};

/**
 *  =================================================
 *  UNIVERSITY VERIFICATION SERVICES
 *  =================================================
 */

/**
 * Get agents for university verification service
 * @returns axios promise
 */

export const getAgentsForUniverstyVerificationService = ({ pageNo = 1 }) => {
  const requestData = {
    url: agentVerificationRequestURL(pageNo),
  };

  return getRequest(requestData);
};

/**
 * Get university list for an agent
 * @returns axios promise
 */

export const getUniversityForVerificationService = ({ pageNo = 1, agentID = "" }) => {
  const requestData = {
    url: universityVerificationURL(pageNo, agentID),
  };

  return getRequest(requestData);
};

/**
 * Update university verification status
 *  * @returns axios promise
 */

export const patchUniversityStatus = (data) => {
  const requestData = {
    url: updateVerificationStatusURL(),
    data: data,
  };

  return patchRequest(requestData);
};

/**
 *  =================================================
 *  SUPPORT SERVICES
 *  =================================================
 */

export const getAllSupportMessageServ = ({ ...query }: SupportMessageParams) => {
  const requestData = {
    url: getAllSupportMessageURL(query),
  };

  return getRequest(requestData);
};

export const updateASupportMsgeStatServ = ({ messageId = "", messageType = "" }) => {
  const requestData = {
    url: getUpdateSupportMsgTypeURL({ messageId, messageType }),
  };

  return patchRequest(requestData);
};

export const deleteASupportMsgeStatServ = ({ messageId = "" }) => {
  const requestData = {
    url: getDelSupportMsgURL({ messageId }),
  };

  return deleteRequest(requestData);
};

/**
 *  =================================================
 *  UNVERIFIED AGENTS SERVICES
 *  =================================================
 */

/**
 * Get unverified agents  service
 * @returns axios promise
 */
export const getAllUnVerifiedAgentsService = ({ pageNo = 1, source = "", search = "" }) => {
  const requestData = {
    url: getUnVerifiedAgentsURL({ pageNo, source, search }),
  };

  return getRequest(requestData);
};

/**
 * Update agent verification
 * @returns axios promise
 */

export const verifyAgentService = ({ email }: { email: string }) => {
  const requestData = {
    url: verifyAgentURL(),
    data: { email },
  };

  return postRequest(requestData);
};

/**
 * Update agent verification
 * @returns axios promise
 */

export const deleteAgentService = ({ agentId }: { agentId: string }) => {
  const requestData = {
    url: deleteAgentURL({ agentId }),
  };

  return deleteRequest(requestData);
};

/**
 *  =================================================
 *  APPLICATIONS SERVICES
 *  =================================================
 */

/**
 * Get all applications
 * @returns axios promise
 */

export const getApplicationsService = ({
  pageNo = 1,
  status = "",
  university = "",
  country = "",
  agent = "",
  search = "",
  source = "",
  intakeYear = "",
  intakeMonth = "",
}) => {
  const requestData = {
    url: applicationURL({ pageNo, status, university, country, agent, search, source, intakeMonth, intakeYear }),
  };

  return getRequest(requestData);
};

export const patchApplicationService = ({ id = "", data = new FormData() }) => {
  const requestData = {
    url: addDocumentURL(id),
    data,
  };

  return patchRequest(requestData);
};

export const getSingleApplicationService = ({ id = "" }) => {
  const requestData = {
    url: getSingleApplicationURL(id),
  };

  return getRequest(requestData);
};

export const updateApplicationService = ({ id = "", data }) => {
  const requestData = {
    url: updateApplicationStatusURL(id),
    data,
  };

  return patchRequest(requestData);
};

/**
 *  =================================================
 *  SPECIAL OFFERS SERVICES
 *  =================================================
 */

/**
 * Get all special offers
 * @returns axios promise
 */

export const getSpecialOffersService = () => {
  const requestData = {
    url: getSpecialOffers(),
  };

  return getRequest(requestData);
};

/**
 * Post special offers
 * @returns axios promise
 */

export const createSpecialOfferService = (data) => {
  const requestData = {
    url: createSpecialOffer(),
    data,
  };

  return postRequest(requestData);
};

/**
 *  =================================================
 *  APPLICATION COMMENTS SERVICES
 *  =================================================
 */

/**
 * Get all comments
 * @returns axios promise
 */

export const getApplicationCommentsServices = ({ id = "" }) => {
  const requestData = {
    url: getCommentURL(id),
  };

  return getRequest(requestData);
};

/**
 * Create new comment
 * @returns axios promise
 */

export const createCommentService = ({ id = "", data }) => {
  const requestData = {
    url: createCommentURL(id),
    data,
  };

  return postRequest(requestData);
};

/**
 * Edit comment
 * @returns axios promise
 */

export const editCommentService = ({ id = "", commentID, data }) => {
  const requestData = {
    url: editCommentURL(id, commentID),
    data,
  };

  return patchRequest(requestData);
};

/**
 * Delete comment
 * @returns axios promise
 */

export const deleteCommentService = ({ id = "", commentID }) => {
  const requestData = {
    url: deleteCommentURL(id, commentID),
  };

  return deleteRequest(requestData);
};

/**
 *  =================================================
 *  UPLOAD FILES SERVICES
 *  =================================================
 */

/**
 * Get all special offers
 * @returns axios promise
 */

export const uploadService = (data) => {
  const requestData = {
    url: fileUploadURL(),
    data,
  };

  return postRequest(requestData);
};

/**
 *  =================================================
 *  BLOG SERVICES
 *  =================================================
 */

/**
 * Create a blog
 * @returns axios promise
 */

export const createBlogService = (data) => {
  const requestData = {
    url: createBlogURL(),
    data,
  };

  return postRequest(requestData);
};

/**
 * Fetch all blogs
 * @returns axios promise
 */
export const getBlogsService = ({ pageNo = 1 }) => {
  const requestData = {
    url: getAllBlogsURL({ pageNo }),
  };

  return getRequest(requestData);
};

/**
 * Fetch a single blog
 * @returns axios promise
 */
export const getSingleBlogService = ({ blogID = "" }) => {
  const requestData = {
    url: getSingleBlogURL({ blogID }),
  };

  return getRequest(requestData);
};

/**
 * Delete a blog
 * @returns axios promise
 */
export const deleteBlogService = ({ blogID = "" }) => {
  const requestData = {
    url: deleteBlogURL({ blogID }),
  };

  return deleteRequest(requestData);
};

/**
 * Edit a blog
 * @returns axios promise
 */
export const editBlogService = ({ blogID = "", data }) => {
  const requestData = {
    url: editBlogURL({ blogID }),
    data,
  };

  return patchRequest(requestData);
};

export interface CreateInstitutionData {
  university: string;
  logo: {
    path: string;
    key: string;
  };
  country: string;
  website: string;
  agent: string[];
  acceptingNationality: string[];
  city?: string;
  commissionPercentage?: number;
  isLanguageSchool: boolean;
  isEuropeanSchool: boolean;
  institutionType: string;
}
/**
 * Create an institution
 * @returns axios promise
 */
export const addInstitutionService = (data: CreateInstitutionData) => {
  const requestData = {
    url: addInstitutionURL(),
    data,
  };

  return postRequest(requestData);
};

/**
 * Fetch institutions
 * @returns axios promise
 */
export const fetchInstitutionsService = ({ pageNo = "", noOfRequests = "", search = "", isLanguageSchool }) => {
  const requestData = {
    url: fetchInstitutionsURL({ pageNo, noOfRequests, search, isLanguageSchool }),
  };

  return getRequest(requestData);
};

/**
 * Fetch verified agents for dropdown
 * @returns axios promise
 */
export const fetchAllVerifiedAgentsService = () => {
  const requestData = {
    url: fetchAllVerifiedAgentsURL(),
  };

  return getRequest(requestData);
};

/**
 * Fetch a single institution
 * @param id
 * @returns axios promise
 */
export const fetchInstitutionService = (id: string) => {
  const requestData = {
    url: fetchInstitutionURL(id),
  };

  return getRequest(requestData);
};

/**
 * Fetch agents in an institution
 * @param id
 * @returns axios promise
 */
export const fetchInstitutionAgentsService = ({ pageNo = "", noOfRequests = "", search = "", id = "" }) => {
  const requestData = {
    url: fetchInstitutionAgentsURL({ pageNo, noOfRequests, search, id }),
  };

  return getRequest(requestData);
};

/**
 * Fetch courses in an institution
 * @param id
 * @returns axios promise
 */
export const fetchInstitutionCoursesService = ({
  pageNo = "",
  noOfRequests = "",
  search = "",
  id = "",
  degree = "",
  attendance = "",
}) => {
  const requestData = {
    url: fetchInstitutionCoursesURL({ pageNo, noOfRequests, search, id, degree, attendance }),
  };

  return getRequest(requestData);
};

/**
 * Fetch a single courses
 * @param id
 * @returns axios promise
 */
export const fetchCourseService = (id: string) => {
  const requestData = {
    url: fetchCourseURL(id),
  };

  return getRequest(requestData);
};

interface updateUniData {
  status: boolean;
}
/**
 *Enable/disable institution
 * @param id
 * @returns axios promise
 */
export const updateStatusInstitutionService = ({ id, data }: { id: string; data: updateUniData }) => {
  const requestData = {
    url: updateStatusInstitutionURL(id),
    data,
  };

  return patchRequest(requestData);
};

interface updateCourseData {
  status: "enable" | "disable";
}
/**
 *Enable/disable course
 * @param id
 * @returns axios promise
 */
export const updateStatusCourseService = ({ id, data }: { id: string; data: updateCourseData }) => {
  const requestData = {
    url: updateStatusCourseURL(id),
    data,
  };

  return patchRequest(requestData);
};

export interface createCourseData {
  name: string;
  degree: string;
  duration: string;
  yearlyTuition: {
    currency: string;
    amount: number;
  };
  initialDeposit: {
    currency: string;
    amount: number;
  };
  description: string;
  applicationFee: {
    currency: string;
    amount: number;
  };
  commission: {
    currency: string;
    amount: number;
  };
  attendance: string;
}
/**
 * Create course
 * @param id
 * @returns axios promise
 */
export const createCourseService = ({ id, data }: { id: string; data: createCourseData }) => {
  const requestData = {
    url: createCourseURL(id),
    data,
  };

  return postRequest(requestData);
};

interface updateAgentData {
  status: "enable" | "disable";
  agentId: string;
}
/**
 *Enable/disable agent under institution
 * @param id
 * @returns axios promise
 */
export const updateStatusAgentService = ({ id, data }: { id: string; data: updateAgentData }) => {
  const requestData = {
    url: updateStatusAgentURL(id),
    data,
  };

  return patchRequest(requestData);
};

/**
 * Fetch agents that are not under an institution
 * @param id
 * @returns axios promise
 */
export const fetchNonAgentsService = (id) => {
  const requestData = {
    url: fetchNonAgentsURL(id),
  };

  return getRequest(requestData);
};

interface AddAgentsData {
  agents: string[];
}
/**
 * Fetch agents that are not under an institution
 * @param id
 * @returns axios promise
 */
export const addAgentsToUniService = ({ id, data }: { id: string; data: AddAgentsData }) => {
  const requestData = {
    url: addAgentsToUniURL(id),
    data,
  };

  return postRequest(requestData);
};

export interface editCourseData {
  name?: string;
  degree?: string;
  duration?: string;
  yearlyTuition?: {
    currency: string;
    amount: number;
  };
  initialDeposit?: {
    currency: string;
    amount: number;
  };
  description?: string;
  applicationFee?: {
    currency: string;
    amount: number;
  };
  commission?: {
    currency: string;
    amount: number;
  };
  attendance?: string;
}
/**
 * Edit a course
 * @param id
 * @returns axios promise
 */
export const editCourseService = ({ id, data }: { id: string; data: editCourseData }) => {
  const requestData = {
    url: editCourseURL(id),
    data,
  };

  return patchRequest(requestData);
};

export interface editUniData {
  logo?: {
    path: string;
    key: string;
  };
  website?: string;
  acceptingNationality?: string[];
  commissionPercentage?: number;
  city?: string;
  country?: string;
  university?: string;
  isLanguageSchool?: boolean;
  isEuropeanSchool?: boolean;
  institutionType?: string;
}
/**
 * Edit a university's profile
 * @param id
 * @returns axios promise
 */
export const editInstitutionService = ({ id, data }: { id: string; data: editUniData }) => {
  const requestData = {
    url: editInstitutionURL(id),
    data,
  };

  return patchRequest(requestData);
};

/**
 * Fetch signups
 * @param pageNo
 * @param noOfRequests
 * @param status
 * @returns axios promise
 */
export const listSignupsService = ({ pageNo = "", noOfRequests = "", status = "", source = "", search = "" }) => {
  const requestData = {
    url: listSignupsURL({ pageNo, noOfRequests, status, source, search }),
  };

  return getRequest(requestData);
};

/**
 * Fetch signups
 * @param id
 * @returns axios promise
 */
export const getOneSignupService = ({ id = "" }) => {
  const requestData = {
    url: getOneSignupURL({ id }),
  };

  return getRequest(requestData);
};

interface verifySignupProp {
  data: { status: "approved" };
  id: string;
}
/**
 * Verify a signup
 * @param id
 * @returns axios promise
 */
export const verifySignupService = ({ data, id }: verifySignupProp) => {
  const requestData = {
    url: verifySignupURL({ id }),
    data,
  };

  return patchRequest(requestData);
};

/**
 * Fetch all prospects
 * @param param0
 * @returns
 */
interface FetchProspectProps {
  agent?: string;
  pageNo: string;
  search: string;
  plan?: string;
  status?: string;
  source?: string;
  actionRequired?: boolean;
}
export const fetchProspectsService = ({
  agent,
  pageNo,
  search,
  plan,
  status,
  source = "",
  actionRequired = undefined,
}: FetchProspectProps) => {
  const requestData = {
    url: fetchProspectsURL(pageNo, agent, search, plan, status, source, actionRequired),
  };

  return getRequest(requestData);
};

/**
 * Fetch single prospect
 * @param id string
 * @returns
 */
export const viewProspectService = ({ id }) => {
  const requestData = {
    url: viewProspectURL(id),
  };

  return getRequest(requestData);
};

interface UpdateProspectStatusType {
  status: "changes_required" | "ready_for_review" | "review_completed";
}
/**
 * Update prospect review status
 * @param id string
 * @returns
 */
export const updateProspectStatusService = ({ id, data }: { id: string; data: UpdateProspectStatusType }) => {
  const requestData = {
    url: updateProspectStatusURL(id),
    data: data,
  };

  return patchRequest(requestData);
};

interface CreateComment {
  name: string;
  comment: string;
}
export const createProspectCommentService = ({ id, data }: { id: string; data: CreateComment }) => {
  const requestData = {
    url: createProspectCommentURL(id),
    data: data,
  };

  return postRequest(requestData);
};

export const fetchProspectCommentService = ({ id }) => {
  const requestData = {
    url: fetchProspectCommentURL(id),
  };

  return getRequest(requestData);
};

/**
 * Edit prospect comment
 * @returns axios promise
 */

export const editProspectCommentService = ({ id = "", commentID, data }) => {
  const requestData = {
    url: editProspectCommentURL(id, commentID),
    data,
  };

  return patchRequest(requestData);
};

/**
 * Delete prospect comment
 * @returns axios promise
 */

export const deleteProspectCommentService = ({ id = "", commentID }) => {
  const requestData = {
    url: deleteProspectCommentURL(id, commentID),
  };

  return deleteRequest(requestData);
};

export const fetchProspectApplicationsService = ({ id = "" }) => {
  const requestData = {
    url: fetchProspectApplicationsURL(id),
  };

  return getRequest(requestData);
};

interface UploadDocType {
  url: string;
  key: string;
}

export const updateProspectDocsService = ({ id = "", data }: { id: string; data: UploadDocType }) => {
  const requestData = {
    url: updateProspectDocsURL(id),
    data: {
      suppDocs: {
        adminSuppDocs: [data],
      },
    },
  };

  return patchRequest(requestData);
};

export const fetchAgentsUnpaginatedService = () => {
  const requestData = {
    url: fetchAgentsUnpaginatedURL(),
  };

  return getRequest(requestData);
};

export const fetchUnisUnpaginatedService = () => {
  const requestData = {
    url: fetchUnisUnpaginatedURL(),
  };

  return getRequest(requestData);
};

export const updateProspectPlanService = ({ id, data }) => {
  const requestData = {
    url: updateProspectPlanURL(id),
    data,
  };

  return patchRequest(requestData);
};

export const fetchProspectsUnpaginatedService = ({ id }) => {
  const requestData = {
    url: fetchProspectsUnpaginatedURL(id),
  };

  return getRequest(requestData);
};

export const fetchCoursesUnpaginatedService = () => {
  const requestData = {
    url: fetchCoursesUnpaginatedURL(),
  };

  return getRequest(requestData);
};

export const fetchCountryUnpaginatedService = () => {
  const requestData = {
    url: fetchCountryUnpaginatedURL(),
  };

  return getRequest(requestData);
};

interface ApplicationData {
  university: string;
  course: string;
  intakeMonth: string;
  intakeYear: string;
  source: string;
}

export interface CreateApplicationData {
  application: ApplicationData[];
  prospect: string;
  agent: string;
}

export const createApplicationService = (data: CreateApplicationData) => {
  const requestData = {
    url: createApplicationURL({ prospectId: data.prospect, agentId: data.agent }),
    data: data.application,
  };

  return postRequest(requestData);
};

export interface ApplicationDataOptional {
  university?: string;
  course?: string;
  intakeMonth?: string;
  intakeYear?: string;
  source?: string;
}

export interface EditApplicationData {
  application: ApplicationDataOptional;
  id: string;
}

export const editApplicationService = (data: EditApplicationData) => {
  const requestData = {
    url: editApplicationURL(data.id),
    data: data.application,
  };

  return patchRequest(requestData);
};

export interface EditProspectData {
  actionRequired?: boolean;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  dob?: string;
  gender?: string;
  maritalStatus?: string;
  fName?: string;
  mName?: string;
  nationality?: string;
  passport?: {
    number?: string;
    issueDate?: string;
    expiryDate?: string;
    issueCountry?: string;
  };
  placeOfBirth?: string;
  countryOfBirth?: string;
  language?: LanguageRequestData[];
  contactInfo?: {
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    email?: string;
    phone?: string;
  };
  education?: EducationHistoryRequestData[];
  suppDocs?: uploadDocsType;
}

export const editProspectService = ({ data, id }: { data: EditProspectData; id: string }) => {
  const requestData = {
    url: editProspectURL(id),
    data: data,
  };

  return patchRequest(requestData);
};

export interface EducationHistoryRequestData {
  level: string;
  insititute: string;
  program: string;
  startDate: string;
  endDate: string;
  country: any;
  language: string;
  aveGrade: string;
  maxGrade: string;
}

export interface LanguageRequestData {
  qualification: string;
  band: string;
  examDate: string;
  readingScore: string;
  writingScore: string;
  speakingScore: string;
  listeningScore: string;
}

export interface CreateProspectRequestData {
  firstName: string;
  lastName: string;
  middleName: string;
  dob: string;
  gender: string;
  maritalStatus: string;
  fName: string;
  mName: string;
  nationality: string;
  passport: {
    number: string;
    issueDate: string;
    expiryDate: string;
    issueCountry: string;
  };
  placeOfBirth: string;
  countryOfBirth: string;
  language: LanguageRequestData[];
  contactInfo: {
    address: string;
    city: string;
    state: string;
    country: string;
    email: string;
    phone: string;
  };
  education: EducationHistoryRequestData[];
  suppDocs: uploadDocsType;
}

export const createProspectService = ({ data, id }: { data: CreateProspectRequestData; id: string }) => {
  const requestData = {
    url: createProspectURL(id),
    data: data,
  };

  return postRequest(requestData);
};

export const fetchAgentProfileService = ({ id }) => {
  const requestData = {
    url: fetchAgentProfileURL(id),
  };

  return getRequest(requestData);
};

export interface EditAgentRequestData {
  address?: string;
  agencyName?: string;
  avatar?: string;
  bankCountry?: string;
  bankHolderName?: string;
  bankName?: string;
  email?: string;
  holderAddress?: string;
  iban?: string;
  name?: string;
  numberOfProspects?: string;
  phone?: string;
  source?: string;
  teamSize?: string;
  website?: string;
}
export const editAgentProfileService = ({ id, data }: { id: string; data: EditAgentRequestData }) => {
  const requestData = {
    url: editAgentProfileURL(id),
    data,
  };

  return patchRequest(requestData);
};

import { Pagination, ApprovedSignupRequestItem, ApprovedSignupRequestUI, Toast, DropDownItem } from "components";
import Preloader from "components/Preloader";
import { getErrorMessage } from "Helper/errorMessage";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "routes/utils";
import { listSignupsService } from "services";
import { optionType } from "types";
import config from "config.json";

const ApprovedSignupRequests = () => {
  const navigate = useNavigate();

  // Current page number for pagination component and data fetching
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [search, setSearch] = React.useState("");
  const [sourceFilter, setSourceFilter] = React.useState<optionType>({ label: "Vobb", value: "vobb" });

  // API Request Variables
  const {
    run: runRequestList,
    data: requestListResponse,
    requestStatus: requestListStatus,
    error: requestListError,
  } = useApiRequest({});

  useEffect(() => {
    runRequestList(
      listSignupsService({
        pageNo: `${currentPageNo}`,
        noOfRequests: "10",
        status: "approved",
        search,
        source: config.whitelabel ? config.source : sourceFilter.value,
      }),
    );
  }, [currentPageNo, search, sourceFilter]);

  const signupList = useMemo<ApprovedSignupRequestItem[]>(() => {
    if (requestListResponse) {
      if (requestListResponse?.status === 200) {
        const data = requestListResponse.data.data;
        return data.fetchedData.map((item) => ({
          agencyName: item.agencyName,
          representativeName: item.name,
          emailAddress: item.email,
          phone: item.phone,
          agencyId: item._id,
          dateCreated: new Date(item.createdAt).toLocaleDateString(),
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(requestListResponse, "Unable to fetch signups"),
          type: false,
        });
      }
    }
    if (requestListError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(requestListError, "Unable to fetch signups"),
        type: false,
      });
    }

    return [];
  }, [requestListResponse, requestListError]);

  const handleSearch = (search) => {
    setCurrentPageNo(1);
    setSearch(search);
  };

  const showPreloader = requestListStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <ApprovedSignupRequestUI
        tableBodyItems={signupList}
        count={requestListResponse?.data?.data?.count ?? 0}
        viewSignup={(id) => navigate(Routes.agentID(id))}
        handleSearch={handleSearch}
        sourceFilter={{ value: sourceFilter, control: (x) => setSourceFilter(x) }}
        currentPage={requestListResponse?.data?.data?.pageNo ?? 0}
      />
      <Pagination
        pages={requestListResponse?.data?.data?.availablePages ?? 0}
        handlePage={(page) => setCurrentPageNo(page)}
        currentPage={requestListResponse?.data?.data?.pageNo ?? 0}
      />
    </>
  );
};

export { ApprovedSignupRequests };

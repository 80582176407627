import { AddIcon, SearchIcon } from "assets";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  ProspectsTableBody,
  ProspectsTableBodyItem,
  PageHeader,
  CustomSelect,
  FilterBox,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import styles from "./styles.module.css";
import { optionType } from "types";
import { initialOptionType, sourceOptions } from "Helper/options";
import { useNavigate } from "react-router-dom";
import { Routes } from "routes/utils";
import config from "config.json";

interface ProspectsProps {
  tableBodyItems: ProspectsTableBodyItem[];
  handleViewProspect: (id: string) => void;
  handleEditProspect: (id: string) => void;
  handleViewComments: (id: string) => void;
  count: number;
  handleSearch: (x: string) => void;
  planFilter: {
    value: optionType;
    control: (x) => void;
  };
  statusFilter: {
    value: optionType;
    control: (x) => void;
  };
  agentFilter: {
    value: optionType;
    control: (x) => void;
  };
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  agentOptions: optionType[];
  currentPage: number;
}

const ProspectsUI: React.FC<ProspectsProps> = ({
  tableBodyItems,
  count,
  handleSearch,
  planFilter,
  statusFilter,
  agentFilter,
  handleViewProspect,
  currentPage,
  sourceFilter,
  agentOptions,
  handleEditProspect,
  handleViewComments,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const [createProspect, setCreateProspect] = React.useState({
    show: false,
    error: false,
    agent: initialOptionType,
  });

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Agent" },
    { title: "Prospect" },
    { title: "Plan" },
    { title: "Status" },
    { title: "Date Created" },
    { title: "" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  const startCreateProspect = () => {
    setCreateProspect({ ...createProspect, show: true });
  };

  const submitAgent = () => {
    if (createProspect.agent.value) {
      setCreateProspect({ ...createProspect, error: false, show: false });
      navigate(Routes.createProspectID(createProspect.agent.value));
    } else {
      setCreateProspect({ ...createProspect, error: true });
    }
  };

  const closeCreateProspect = () => {
    setCreateProspect({
      error: false,
      show: false,
      agent: initialOptionType,
    });
  };

  return (
    <section>
      <PageHeader title="Prospects" description={`Prospects added on ${config.name}`} count={count} />
      <div className={styles.createSec}>
        {createProspect.show ? (
          <CustomSelect
            onChange={(x) => setCreateProspect({ ...createProspect, agent: x })}
            validatorMessage={createProspect.error ? "Select an agent " : ""}
            inputClass={styles.select}
            name={"agent"}
            placeholder={"Select..."}
            label={"Agent*"}
            options={agentOptions}
            value={createProspect.agent}
            parentClassName={styles.inputWrap}
          />
        ) : (
          ""
        )}
        <div className={styles.btnSec}>
          {!createProspect.show ? (
            <button onClick={startCreateProspect} className={styles.createBtn}>
              <AddIcon /> Create prospect
            </button>
          ) : (
            <>
              <button onClick={submitAgent} className={styles.createBtn}>
                Submit
              </button>
              <button onClick={closeCreateProspect} className={styles.closeBtn}>
                Close
              </button>
            </>
          )}
        </div>
      </div>

      <div className={styles.searchFilterWrapper}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by prospect name"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch(search);
            }}
          />
        </div>
        <div className={styles.filterWrapper}>
          {!config.whitelabel ? (
            <FilterBox
              onChange={(x) => sourceFilter.control(x)}
              validatorMessage={""}
              inputClass={styles.select}
              name={"source"}
              placeholder={"Select..."}
              label={"Source"}
              options={[{ label: "All", value: "" }, ...sourceOptions]}
              value={sourceFilter.value}
              parentClassName={`${styles.dropdownWrap}`}
            />
          ) : (
            ""
          )}
          <FilterBox
            onChange={(x) => agentFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"agent"}
            placeholder={"Select..."}
            label={"Agent"}
            options={[{ label: "All", value: "" }, ...agentOptions]}
            value={agentFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
          <FilterBox
            onChange={(x) => planFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"plan"}
            placeholder={"Select..."}
            label={"Plan"}
            options={[
              { label: "All", value: "" },
              { label: "Sapphire", value: "free" },
              { label: "Gold", value: "gold" },
              { label: "Ruby", value: "ruby" },
              { label: "Platinum", value: "enterprise" },
            ]}
            value={planFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
          <FilterBox
            onChange={(x) => statusFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"status"}
            placeholder={"Select..."}
            label={"Prospect status"}
            options={[
              { label: "All", value: "" },
              { label: "Created", value: "created" },
              { label: "Ready for review", value: "ready_for_review" },
              { label: "Changes required", value: "changes_required" },
              { label: "Review complete", value: "review_complete" },
              { label: "Pending payment", value: "pending_payment" },
            ]}
            value={statusFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
        </div>
      </div>
      {tableBodyItems.length > 0 && (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <ProspectsTableBody
              currentPage={currentPage}
              handleView={handleViewProspect}
              tableBodyClassName={styles.tableBodyClass}
              tableBodyItems={tableBodyItems}
              handleEdit={handleEditProspect}
              handleViewComments={handleViewComments}
            />
          }
        />
      )}
    </section>
  );
};

export { ProspectsUI };

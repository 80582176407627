import { Pagination, InstitutionsUI, InstitutionsTableBodyItem, AddInstitutionUI, Toast } from "components";
import React, { useMemo } from "react";
import { optionType } from "types";
import { useApiRequest } from "hooks";
import {
  addInstitutionService,
  CreateInstitutionData,
  fetchAllVerifiedAgentsService,
  fetchInstitutionsService,
  updateStatusInstitutionService,
  uploadService,
} from "services";
import Preloader from "components/Preloader";
import { getCountry } from "Helper/country";
import { Routes } from "routes/utils";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "Helper/errorMessage";
import config from "config.json";

const Institutions: React.FC = () => {
  const navigate = useNavigate();

  const [showAddUni, setShowAddUni] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [count, setCount] = React.useState<number>(0);
  const [uniData, setUniData] = React.useState<CreateInstitutionData>({
    university: "",
    logo: {
      path: "",
      key: "",
    },
    country: "",
    website: "",
    agent: [],
    acceptingNationality: [],
    isLanguageSchool: false,
    isEuropeanSchool: false,
    institutionType: "",
  });
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [filter, setFilter] = React.useState({
    languageSchool: { label: "All", value: "" },
  });

  const { run: runAgentsList, data: agentsData, requestStatus: agentsStatus, error: agentsError } = useApiRequest({});
  const {
    run: runCreateUni,
    data: createResponse,
    requestStatus: createStatus,
    error: createError,
  } = useApiRequest({});
  const {
    run: runListUnis,
    data: listUniResponse,
    requestStatus: listUniStatus,
    error: listUniError,
  } = useApiRequest({});
  const {
    run: runUploadImage,
    data: imageUploadResponse,
    requestStatus: imageUploadStatus,
    error: imageUploadError,
  } = useApiRequest({});
  const {
    run: runUniUpdateStatus,
    data: uniUpdateStatusData,
    requestStatus: uniUpdateStatusStatus,
    error: uniUpdateStatusError,
  } = useApiRequest({});

  const fetchUni = () =>
    runListUnis(
      fetchInstitutionsService({
        pageNo: `${currentPage}`,
        noOfRequests: "10",
        search: config.whitelabel && config.institution?.enabled ? config.institution.name : search,
        isLanguageSchool: filter.languageSchool.value,
      }),
    );

  React.useEffect(() => {
    runAgentsList(fetchAllVerifiedAgentsService());
  }, []);

  React.useEffect(() => {
    fetchUni();
  }, [currentPage, search, filter]);

  const submitForm = (data) => {
    setUniData({
      ...data,
    });

    const formData = new FormData();
    formData.append("files", data.logo);

    runUploadImage(uploadService(formData));
  };
  const handleSearch = (search) => {
    setCurrentPage(1);
    setSearch(search);
  };

  // Fetch verified agents
  const verifiedAgents = useMemo<optionType[]>(() => {
    if (agentsData) {
      if (agentsData?.status === 200) {
        return agentsData.data.data
          .map((agency) => ({
            label: agency.agencyName,
            value: agency._id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(agentsData, "Unable to fetch super agents"),
          type: false,
        });
      }
    }
    if (agentsError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(agentsError, "Unable to fetch super agents"),
        type: false,
      });
    }

    return [];
  }, [agentsData, agentsData?.data?.data, agentsError]);

  // List universities
  const uniList = useMemo<InstitutionsTableBodyItem[]>(() => {
    if (listUniResponse) {
      if (listUniResponse?.status === 200) {
        const data = listUniResponse.data.data;
        setTotalPages(data.availablePages);
        setCount(data.count);
        setCurrentPage(data.pageNo);

        return data.fetchedRequest.map((item) => ({
          logo: item.logo.path,
          name: item.university,
          websiteUrl: item.website,
          country: getCountry(item.country),
          id: item._id,
          status: !item.isdisabled,
        }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(listUniResponse, "Unable to fetch universities"),
          type: false,
        });
      }
    }
    if (listUniError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(listUniError, "Unable to fetch universities"),
        type: false,
      });
    }

    return [];
  }, [listUniResponse, listUniStatus, listUniError]);

  React.useMemo(() => {
    if (imageUploadResponse) {
      if (imageUploadResponse?.status === 200) {
        const files = imageUploadResponse.data.data.map((item) => ({
          path: item.location,
          key: item.key,
        }));

        runCreateUni(addInstitutionService({ ...uniData, logo: files[0] }));
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(imageUploadResponse, "Unable to upload image"),
          type: false,
        });
      }
    }
    if (imageUploadError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(imageUploadError, "Unable to upload image"),
        type: false,
      });
    }
  }, [imageUploadResponse, imageUploadStatus, imageUploadError]);

  React.useMemo(() => {
    if (createResponse) {
      if (createResponse.status === 200) {
        setShowAddUni(false);
        fetchUni();
        console.log(createResponse);
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(createResponse, "Unable to create institution"),
          type: false,
        });
      }
    }
    if (createError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(createError, "Unable to create institution"),
        type: false,
      });
    }
  }, [createResponse, createStatus, createError]);

  const updateUniStatus = (id, status) => {
    runUniUpdateStatus(updateStatusInstitutionService({ id, data: { status: status } }));
  };

  React.useMemo(() => {
    if (uniUpdateStatusData) {
      if (uniUpdateStatusData.status === 200) {
        fetchUni();
      } else {
        setToast({
          show: true,
          heading: "Error!",
          text: getErrorMessage(uniUpdateStatusData, "Unable to update university status"),
          type: false,
        });
      }
    }

    if (uniUpdateStatusError) {
      setToast({
        show: true,
        heading: "Error!",
        text: getErrorMessage(uniUpdateStatusError, "Unable to update university status"),
        type: false,
      });
    }
  }, [uniUpdateStatusData, uniUpdateStatusError]);

  const showPreloader =
    imageUploadStatus.isPending ||
    createStatus.isPending ||
    listUniStatus.isPending ||
    agentsStatus.isPending ||
    uniUpdateStatusStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      {showAddUni && (
        <AddInstitutionUI
          show={showAddUni}
          close={() => setShowAddUni(false)}
          submit={submitForm}
          agentList={verifiedAgents}
        />
      )}
      <InstitutionsUI
        tableBodyItems={uniList}
        handleViewInstitution={(id) => navigate(Routes.institutionID(id))}
        count={count}
        addUni={() => setShowAddUni(!showAddUni)}
        handleSearch={handleSearch}
        updateStatus={updateUniStatus}
        filter={{
          languageSchool: { value: filter.languageSchool, control: (x) => setFilter({ ...filter, languageSchool: x }) },
        }}
      />
      <Pagination currentPage={currentPage} pages={totalPages} handlePage={(x) => setCurrentPage(x)} />
    </>
  );
};

export { Institutions };

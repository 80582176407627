import { avatarImg, CaretLeftBlue, DownloadIcon, ViewCommentIcon } from "assets";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  applicationInfo,
  ProspectApplicationTable,
  ProspectApplicationTableItem,
  SelectBox,
  Toggle,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import { ProspectInfo } from "pages";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "routes/utils";
import styles from "./styles.module.css";

interface DocProps {
  name: string;
  url: string;
}

const Doc = ({ name, url }: DocProps) => {
  return (
    <div className={`${styles.doc}`}>
      <a href={url} target="_blank" rel="noreferrer" download={name}>
        <DownloadIcon title="download" />
      </a>
      {name}
    </div>
  );
};

interface ViewProspectProps {
  prospect: ProspectInfo;
  changeStatus: (x: string) => void;
  changePlan: () => void;
  viewComments: () => void;
  applications: ProspectApplicationTableItem[];
  viewAppComments: (x: applicationInfo) => void;
  loading: boolean;
  uploadDoc: (x: File) => void;
  changeActionStatus: (x: boolean) => void;
}

const ViewProspectUI: React.FC<ViewProspectProps> = ({
  prospect,
  changePlan,
  changeStatus,
  viewComments,
  applications,
  viewAppComments,
  loading,
  uploadDoc,
  changeActionStatus,
}) => {
  const { personal, contact, eduHistory, languages, documents, planInfo } = prospect;

  const tableHeaderTitles: TableHeaderItemProps[] = [
    // { title: "ID" },
    { title: "University" },
    { title: "Program" },
    { title: "Intake" },
    { title: "Status" },
    { title: "Comments" },
  ];

  const navigate = useNavigate();

  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const selectBoxStyles = {
    background: "#e9eeff",
    color: "inherit",
    fontWeight: "500",
    minWidth: "unset",
    border: "none",
    padding: "8px 2rem 8px 16px",
    borderRadius: "50px",
    fontSize: "13px",
    paddingRight: "2rem",
    justifyContent: "flex-end",
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const doc = new File([file], file.name);

    uploadDoc(doc);
  };

  const docLength =
    documents.general.length + documents.admin_documents.length + documents.others.length + documents.references.length;

  return (
    <>
      {!loading ? (
        <main className={`appContainer ${styles.prospectContainer}`}>
          <button onClick={viewComments} className={styles.viewComment}>
            <ViewCommentIcon title="View comments" />
            {personal.unreadComments ? (
              <div className={styles.commentCount}>
                {personal.unreadComments}{" "}
                <div className={styles.commentCountDescrip}>{personal.unreadComments} new comment(s)</div>
              </div>
            ) : (
              ""
            )}
          </button>

          <section className={styles.backSec}>
            <button className={styles.backBtn} onClick={() => navigate(Routes.prospects)} color="blue">
              <CaretLeftBlue /> Back
            </button>
          </section>
          <section className={styles.heading}>
            <h1 className={styles.hd}>Prospect Information</h1>
            <div className={styles.hdCTA}>
              <button onClick={changePlan} className={styles.updatePaymentBtn}>
                Update plan
              </button>
              <SelectBox
                selectGroupID="review status"
                dropPosition="bottom"
                displayStyles={selectBoxStyles}
                dropdownItems={[{ title: "Changes required" }, { title: "Review completed" }, { title: "Pending payment" }]}
                onDropItemSelect={(e) => changeStatus(e.title)}
                defaultSelected={{ title: personal.status }}
                containerClassName={styles.dropdownWrap}
                label=""
              />
            </div>
          </section>
          <div className={styles.toggle}>
            <Toggle onClick={() => changeActionStatus(!prospect.actionRequired)} checked={prospect.actionRequired} />{" "}
            <span>{prospect.actionRequired ? "Action Needed" : "No action needed"}</span>
          </div>
          <section className={styles.agentInfo}>
            <div className={styles.personal}>
              {planInfo.plan !== "free" ? (
                <span className={styles.paymentStatus}>{planInfo.paymentStatus ? "Paid" : "Pending payment"}</span>
              ) : (
                ""
              )}
              <p className={styles.name}>{personal.firstName + " " + personal.middleName + " " + personal.lastName}</p>
              <p className={styles.prospectPlan}>
                {planInfo.plan === "free"
                  ? `Sapphire (free)`
                  : planInfo.plan === "gold"
                  ? `Gold ($65)`
                  : planInfo.plan === "ruby"
                  ? "Ruby ($95)"
                  : ""}{" "}
                PLAN
              </p>
              <img
                className={styles.avatar}
                src={!personal.passportPhoto || personal.passportPhoto === "" ? avatarImg : personal.passportPhoto}
                alt="Student passport photo"
              />
              <div className={styles.data}>
                <label>GENDER</label>
                <p>{personal.gender}</p>
              </div>
              <div className={styles.data}>
                <label>NATIONALITY</label>
                <p>{personal.nationality}</p>
              </div>
              <div className={styles.data}>
                <label>PASSPORT NO.</label>
                <p>{personal.passportNo}</p>
              </div>
              <div className={styles.data}>
                <label>D.O.B</label>
                <p>{personal.dateofBirth}</p>
              </div>
              <div className={styles.data}>
                <label>STATUS</label>
                <p>{personal.maritalStatus}</p>
              </div>
              <div className={styles.data}>
                <label>FATHER&apos;S NAME</label>
                <p>{personal.fathersName}</p>
              </div>
              <div className={styles.data}>
                <label>MOTHER&apos;S NAME</label>
                <p>{personal.mothersName}</p>
              </div>
              <div className={styles.data}>
                <label>PLACE OF BIRTH</label>
                <p>{personal.birthPlace}</p>
              </div>
            </div>
            <div className={styles.passport}>
              <p className={styles.ttl}>Passport Information</p>

              <div className={styles.info}>
                <div>
                  <label>PASSPORT NO.</label>
                  <p>{personal.passportNo}</p>
                </div>
                <div>
                  <label>ISSUE DATE</label>
                  <p>{personal.issueDate}</p>
                </div>
                <div>
                  <label>EXPIRY DATE</label>
                  <p>{personal.expiryDate}</p>
                </div>
                <div>
                  <label>COUNTRY</label>
                  <p>{personal.countryIssued}</p>
                </div>
              </div>
            </div>
            <div className={styles.contact}>
              <p className={styles.ttl}>Contact Information</p>
              <div className={styles.info}>
                <div className={styles.full}>
                  <label>EMAIL</label>
                  <a className={styles.email} href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                </div>
                <div className={styles.full}>
                  <label>ADDRESS</label>
                  <p>{contact.address}</p>
                </div>
                <div>
                  <label>COUNTRY</label>
                  <p>{contact.country}</p>
                </div>
                <div>
                  <label>CITY/TOWN</label>
                  <p>{contact.city}</p>
                </div>
                <div>
                  <label>STATE</label>
                  <p>{contact.state}</p>
                </div>
                <div>
                  <label>PHONE</label>
                  <p>{contact.phone}</p>
                </div>
              </div>
            </div>
            <div className={styles.university}>
              <p className={styles.ttl}>Education History</p>
              {eduHistory.length > 0 ? (
                eduHistory.map((item, index) => {
                  const isGrade12 = item.level === "Grade 12 certificate";
                  return (
                    <div key={index} className={styles.info}>
                      <div className={styles.full}>
                        <label>INSTITUTE</label>
                        <p>{item.institute}</p>
                      </div>
                      {!isGrade12 ? (
                        <div>
                          <label>PROGRAM</label>
                          <p>{item.program}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={isGrade12 ? styles.full : ""}>
                        <label>LEVEL</label>
                        <p>{item.level}</p>
                      </div>
                      <div>
                        <label>COUNTRY</label>
                        <p>{item.country}</p>
                      </div>
                      <div>
                        <label>STUDY LANGUAGE</label>
                        <p>{item.studyLanguage}</p>
                      </div>
                      <div>
                        <label>START DATE</label>
                        <p>{item.startDate}</p>
                      </div>
                      <div>
                        <label>END DATE</label>
                        <p>{item.endDate}</p>
                      </div>

                      {!isGrade12 ? (
                        <>
                          <div>
                            <label>GPA</label>
                            <p>{item.grade}</p>
                          </div>
                          <div>
                            <label>MAXIMUM GPA</label>
                            <p>{item.maximumGrade}</p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              ) : (
                <p style={{ textAlign: "center" }}>No education history</p>
              )}
            </div>
            <div className={styles.source}>
              <p className={styles.ttl}>Language Qualification(s)</p>
              {languages.length > 0 ? (
                languages.map((item, index) => (
                  <div key={index} className={styles.info}>
                    <div className={styles.full}>
                      <label>QUALIFICATION</label>
                      <p>{item.qualification}</p>
                    </div>
                    <div>
                      <label>BAND</label>
                      <p>{item.band}</p>
                    </div>
                    <div>
                      <label>EXAM DATE</label>
                      <p>{item.examDate}</p>
                    </div>
                    <div>
                      <label>READING SCORE</label>
                      <p>{item.readingScore}</p>
                    </div>
                    <div>
                      <label>LISTENING SCORE</label>
                      <p>{item.listeningScore}</p>
                    </div>
                    <div>
                      <label>WRITING SCORE</label>
                      <p>{item.writingScore}</p>
                    </div>
                    <div>
                      <label>SPEAKING SCORE</label>
                      <p>{item.speakingScore}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center" }}>No language qualifications</p>
              )}
            </div>
            <div className={styles.documents}>
              <p className={styles.ttl}>Documents</p>
              <label htmlFor="doc">
                <DownloadIcon className={styles.uploadDoc} role="button" />
                <input
                  type="file"
                  accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xlsx, .csv, .xls"
                  onChange={handleUpload}
                  id="doc"
                  style={{ display: "none" }}
                />
              </label>

              <div>
                {docLength > 0 ? (
                  <>
                    {Object.keys(documents).map((key) =>
                      documents[key].length > 0 ? (
                        <div className={styles.documentGroup}>
                          <p>{key.replace("_", " ")}</p>
                          <div className={styles.documentList}>
                            {documents[key].map((item, index) => (
                              <Doc name={item.name} url={item.url} key={index} />
                            ))}
                          </div>
                        </div>
                      ) : (
                        ""
                      ),
                    )}
                  </>
                ) : (
                  <p style={{ textAlign: "center", width: "100%" }}>You haven&apos;t added any documents</p>
                )}
              </div>
            </div>
          </section>
          <>
            <h4>Applications ({applications.length})</h4>
            <AdminDashBoardTable
              tableHeaderTitles={tableHeaderTitles}
              AdminTableBody={
                <ProspectApplicationTable
                  tableBodyItems={applications}
                  viewComment={viewAppComments}
                  tableBodyClassName={styles.tableBodyClass}
                />
              }
              customTableClasses={CustomTableClasses}
              showEmptyTableElement={applications.length === 0}
            />
          </>
        </main>
      ) : (
        ""
      )}
    </>
  );
};

export { ViewProspectUI };

import { AdminDashBoardTable, AllCustomTableClasses, FilterBox, PageHeader, SelectBox } from "components";
import { VerifiedAgentsErrorFallBack } from "components/ErrorFallbackUI";
import Preloader from "components/Preloader";
import { TableHeaderItemProps } from "components/table/components";
import { VerifiedAgentsTableBody } from "components/table/tableBodyVariants/VerifiedAgentsTable";
import React from "react";
import { VerifiedAgencyItem } from "../table/tableBodyVariants/VerifiedAgentsTable";
import { VerifiedAgentsDropdownActions } from "../table/tableBodyVariants/VerifiedAgentsTable/index";
import { EmptyTableBody } from "./components";
import styles from "./styles.module.css";
import { SearchIcon } from "assets";
import { sourceOptions } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

// UI Props
interface IProps {
  preformattedAgentsData: VerifiedAgencyItem[];
  showPreloader: boolean;
  showErrorFallback: boolean;
  agentsFetchrequestError: unknown;
  agentDeleterequestError: any;
  rerunfetchAgents: () => void;
  handleAgentDelete: (agent: VerifiedAgencyItem) => void;
  tableHeaderTitles: TableHeaderItemProps[];
  totalAgentsCount: number;
  dropdownActions: VerifiedAgentsDropdownActions;
  currentPage: number;
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  handleSearch: (x: string) => void;
}

// UI meant for the verified agents page
const VerifiedAgentsUI: React.FC<IProps> = (props) => {
  const {
    preformattedAgentsData,
    showPreloader,
    showErrorFallback,
    agentsFetchrequestError,
    agentDeleterequestError,
    rerunfetchAgents,
    dropdownActions,
    tableHeaderTitles,
    totalAgentsCount,
    currentPage,
    sourceFilter,
    handleSearch,
  } = props;

  const [search, setSearch] = React.useState("");

  // All custom Table Classes
  const customTableClasses: AllCustomTableClasses = {
    tableWrapperClass: styles.tableWrapperClass,
    tableContainerClassName: styles.tableContainerClass,
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  return (
    <React.Fragment>
      <PageHeader
        title="Verified Agents"
        description="The table below represents a list of agents that have verified their email addresses."
        count={totalAgentsCount}
      />
      <div className={styles.searchFilterWrapper}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by email, agency or representative name"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch(search);
            }}
          />
        </div>
        <div className={styles.filterContainer}>
          {!config.whitelabel ? (
            <FilterBox
              onChange={(x) => sourceFilter.control(x)}
              validatorMessage={""}
              inputClass={styles.select}
              name={"source"}
              placeholder={"Select..."}
              label={"Source"}
              options={[{ label: "All", value: "" }, ...sourceOptions]}
              value={sourceFilter.value}
              parentClassName={`${styles.dropdownWrap}`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {showPreloader ? (
        <Preloader />
      ) : showErrorFallback ? (
        <VerifiedAgentsErrorFallBack
          agentsFetchrequestError={agentsFetchrequestError}
          deleteRequestError={agentDeleterequestError}
          rerunfetchAgents={rerunfetchAgents}
          retryDelete={dropdownActions.handleAgentDelete}
          showDeleteButtonGroup={!!agentDeleterequestError?.message}
          handlePageReload={() => window.location.reload()}
        />
      ) : (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={{ ...customTableClasses }} // custom class object
          EmptyTableElement={<EmptyTableBody />} // UI for empty table body
          showEmptyTableElement={preformattedAgentsData?.length < 1} //Check to show an empty agents body
          AdminTableBody={
            <VerifiedAgentsTableBody
              currentPage={currentPage}
              tableBodyItems={preformattedAgentsData}
              dropdownActions={dropdownActions}
              tableBodyClassName={styles.tableBodyClass}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export { VerifiedAgentsUI };

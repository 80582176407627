import { AdminLoginUI, Toast } from "components";
import React from "react";
import { loginRequestService } from "services";
import { useNavigate } from "react-router-dom";
import { useApiRequest } from "hooks";
import Preloader from "components/Preloader";
import { Routes } from "routes/utils";
import config from "config.json";

const AdminLogin: React.FC = () => {
  const navigate = useNavigate();
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: true,
  });

  const { run: runLoginRequest, data: loginResponse, requestStatus, error } = useApiRequest({});

  const Login = (email, password, captcha) => {
    runLoginRequest(loginRequestService({ data: { email, password, captcha } }));
  };

  React.useEffect(() => {
    if (!loginResponse) {
      return;
    }

    if (loginResponse.status === 200 && loginResponse.data.success && requestStatus.isResolved) {
      const data = loginResponse.data.data;
      const login = () => {
        localStorage.setItem("vobbAdminToken", data.token);
        localStorage.setItem("vobbAdminID", data.user._id);
        openToast("", loginResponse.data.message + "!", true);
        setTimeout(() => {
          navigate(Routes.institutions);
        }, 1500);
      };

      if (loginResponse.data.data.user.role === "admin") {
        if (!config.whitelabel && data.user.source !== "vobb") {
          openToast("Access Denied", "You're not authorized to access this admin", false);
        } else {
          login();
        }
      } else {
        openToast("", "Please login with an admin account", false);
      }
    } else {
      openToast("Oops", error?.response?.data?.message ?? loginResponse?.response?.data?.message, false);
    }
  }, [requestStatus, loginResponse, error]);

  const openToast = (hd, txt, type) => {
    setToast({
      show: true,
      heading: hd,
      text: txt,
      type: type,
    });
    setTimeout(() => {
      setToast({
        ...toast,
        show: false,
      });
    }, 2000);
  };

  return (
    <>
      {requestStatus.isPending && <Preloader />}
      <Toast
        show={toast.show}
        closeModal={() => setToast({ ...toast, show: false })}
        type={toast.type}
        heading={toast.heading}
        text={toast.text}
      />
      <AdminLoginUI onSubmit={Login} />
    </>
  );
};

export { AdminLogin };

import {
  Pagination,
  ApplicationDetails,
  applicationInfo,
  CommentProps,
  Toast,
  DropDownItem,
  ApplicationData,
  CommissionsUI,
  CommissionsTableBodyItem,
} from "components";
import Preloader from "components/Preloader";
import { getCountry, getNationality } from "Helper/country";
import { getErrorMessage } from "Helper/errorMessage";
import { useApiRequest } from "hooks";
import React, { useEffect, useMemo } from "react";
import {
  createCommentService,
  deleteCommentService,
  editCommentService,
  fetchAgentsUnpaginatedService,
  fetchUnisUnpaginatedService,
  getApplicationCommentsServices,
  getApplicationsService,
  updateApplicationService,
} from "services";
import { initialOptionType } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

const initialState: applicationInfo = {
  university: "",
  degree: "",
  name: "",
  course: "",
  passportPhoto: "",
  id: "",
};

const Commissions: React.FC = () => {
  const [currentPage, setCurrentPage] = React.useState({
    payable: 1,
    paid: 1,
  });
  const [viewApplication, setViewApplication] = React.useState<{ show: boolean; application: applicationInfo }>({
    show: false,
    application: initialState,
  });
  const [clearComment, setClearComment] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });
  const [search, setSearch] = React.useState({
    payable: "",
    paid: "",
  });
  const [filter, setFilter] = React.useState({
    agent: { label: "All", value: "" },
    university: { label: "All", value: "" },
    source: { label: "Vobb", value: "vobb" },
    intakeYear: { label: "All", value: "" },
    intakeMonth: { label: "All", value: "" },
  });

  const { run: runPayable, data: payableData, requestStatus: payableStatus } = useApiRequest({});
  const { run: runPaid, data: paidData, requestStatus: paidStatus } = useApiRequest({});

  const { run: runCommentsFetch, data: commentsData, requestStatus: commentStatus } = useApiRequest({});
  const {
    run: runCreateComment,
    data: createCommentData,
    requestStatus: createCommentStatus,
    error: createCommentError,
  } = useApiRequest({});
  const {
    run: runEditComment,
    data: editCommentData,
    requestStatus: editCommentStatus,
    error: editCommentError,
  } = useApiRequest({});
  const {
    run: runDeleteComment,
    data: deleteCommentData,
    requestStatus: deleteCommentStatus,
    error: deleteCommentError,
  } = useApiRequest({});
  const {
    run: runUpdateStatus,
    data: updateStatusData,
    requestStatus: updateAppStatusStatus,
    error: updateAppStatusError,
  } = useApiRequest({});
  const {
    run: runAllUnis,
    data: allUnisResponse,
    requestStatus: allUnisStatus,
    error: allUnisError,
  } = useApiRequest({});
  const {
    run: runAllAgents,
    data: allAgentsResponse,
    requestStatus: allAgentsStatus,
    error: allAgentsError,
  } = useApiRequest({});

  const fetchAllAgents = () => {
    runAllAgents(fetchAgentsUnpaginatedService());
  };

  const fetchAllUnis = () => {
    runAllUnis(fetchUnisUnpaginatedService());
  };

  React.useEffect(() => {
    fetchAllAgents();
    fetchAllUnis();
  }, []);

  const allAgents = useMemo<optionType[]>(() => {
    if (allAgentsResponse && allAgentsResponse?.status === 200) {
      return allAgentsResponse?.data?.data?.map((item) => ({
        label: item.agencyName,
        value: item._id,
      }));
    } else if (allAgentsError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(allAgentsResponse, "Unable to fetch agents"),
        type: false,
      });
    }

    return [];
  }, [allAgentsResponse, allAgentsError]);

  const allUnis = useMemo<optionType[]>(() => {
    if (allUnisResponse && allUnisResponse?.status === 200) {
      return allUnisResponse?.data?.data?.map((item) => ({
        label: item.university,
        value: item._id,
      }));
    } else if (allUnisError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(allUnisResponse, "Unable to fetch universities"),
        type: false,
      });
    }

    return [];
  }, [allUnisResponse, allUnisError]);

  const fetchPayable = () => {
    runPayable(
      getApplicationsService({
        pageNo: currentPage.payable,
        search: search.payable,
        university: filter.university.value,
        agent: filter.agent.value,
        status: "payable",
        source: config.whitelabel ? config.source : filter.source.value,
        intakeYear: filter.intakeYear.value,
        intakeMonth: filter.intakeMonth.value.toLowerCase(),
      }),
    );
  };

  const fetchPaid = () => {
    runPaid(
      getApplicationsService({
        pageNo: currentPage.paid,
        search: search.paid,
        university: filter.university.value,
        agent: filter.agent.value,
        status: "paid",
        source: config.whitelabel ? config.source : filter.source.value,
        intakeYear: filter.intakeYear.value,
        intakeMonth: filter.intakeMonth.value.toLowerCase(),
      }),
    );
  };

  // Fetch payable
  useEffect(() => {
    fetchPayable();
  }, [currentPage.payable, filter, search.payable]);

  // Fetch payable
  useEffect(() => {
    fetchPaid();
  }, [currentPage.paid, filter, search.paid]);

  // Formatted list of applications agents
  const payableList = useMemo<CommissionsTableBodyItem[]>(() => {
    if (payableData?.status === 200) {
      const fetchedApplications = payableData?.data?.data?.fetchedData;

      return fetchedApplications.map((application) => ({
        status: application?.receivingAgent?.status === "new" ? "sent" : application?.receivingAgent?.status,
        id: application?._id,
        unreadCommentCount: {
          student: application?.student?.commentCount,
          subAgent: application?.sendingAgent?.commentCount,
          superAgent: application?.receivingAgent?.commentCount,
        },
        intakeMonth: application?.intakeMonth,
        intakeYear: application?.intakeYear,
        dateCreated: new Date(application.createdAt).toLocaleDateString(),
        agent: { name: application?.agent_info.agencyName ?? "Deleted Agent", id: application?.agent_info._id },
        source: application?.source,
        university: {
          name: application.university_Info.university,
          id: application.university_Info._id,
          logo: application.university_Info.logo.path,
          country: { name: getCountry(application.university_Info.country), code: application.university_Info.country },
        },
        course: {
          name: application.course.name,
          id: application.course._id,
          degree: application.course.degree,
        },
        prospect: {
          name: `${application.prospect_Info.firstName} ${application.prospect_Info.lastName}`,
          id: application.prospect_Info._id,
          photo: application.prospect_Info.suppDocs.passId.url,
          nationality: getNationality(application?.prospect_Info.nationality),
        },
        commission: {
          currency: application.course.commission.currency,
          amount: application.course.commission.amount,
        },
      }));
    }

    return [];
  }, [payableData]);

  const paidList = useMemo<CommissionsTableBodyItem[]>(() => {
    if (paidData?.status === 200) {
      const fetchedApplications = paidData?.data?.data?.fetchedData;

      return fetchedApplications.map((application) => ({
        status: application?.receivingAgent?.status === "new" ? "sent" : application?.receivingAgent?.status,
        id: application?._id,
        unreadCommentCount: {
          student: application?.student?.commentCount,
          subAgent: application?.sendingAgent?.commentCount,
          superAgent: application?.receivingAgent?.commentCount,
        },
        intakeMonth: application?.intakeMonth,
        intakeYear: application?.intakeYear,
        dateCreated: new Date(application.createdAt).toLocaleDateString(),
        agent: { name: application?.agent_info.agencyName ?? "Deleted Agent", id: application?.agent_info._id },
        source: application?.source,
        university: {
          name: application.university_Info.university,
          id: application.university_Info._id,
          logo: application.university_Info.logo.path,
          country: { name: getCountry(application.university_Info.country), code: application.university_Info.country },
        },
        course: {
          name: application.course.name,
          id: application.course._id,
          degree: application.course.degree,
        },
        prospect: {
          name: `${application.prospect_Info.firstName} ${application.prospect_Info.lastName}`,
          id: application.prospect_Info._id,
          photo: application.prospect_Info.suppDocs.passId.url,
          nationality: getNationality(application?.prospect_Info.nationality),
        },
        commission: {
          currency: application.course.commission.currency,
          amount: application.course.commission.amount,
        },
      }));
    }

    return [];
  }, [paidData]);

  // Formatted comments agents
  const preformattedComments = useMemo<CommentProps[]>(() => {
    if (commentsData?.status === 200) {
      const fetchedComments = commentsData?.data?.data;

      fetchPaid();
      fetchPayable();
      return fetchedComments.map((item) => ({
        name: item?.name,
        role: item?.agent?.role,
        comment: item.comment,
        date: item.time,
        id: item._id,
        userID: item?.agent?._id,
      }));
    }

    return [];
  }, [commentsData]);

  // Create comment response
  useMemo(() => {
    if (createCommentData?.status === 200) {
      setClearComment(!clearComment);
      runCommentsFetch(getApplicationCommentsServices({ id: createCommentData?.data?.data?.applicationID }));
    } else if (createCommentStatus.isRejected || createCommentStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          createCommentError?.response?.data?.message ??
          createCommentData?.response?.data?.message ??
          "Failed to add comment. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [createCommentData, createCommentError, createCommentStatus]);

  // Edit comment response
  useMemo(() => {
    if (editCommentData?.status === 200) {
      setClearComment(!clearComment);
      runCommentsFetch(getApplicationCommentsServices({ id: editCommentData?.data?.data?.applicationID }));
    } else if (editCommentStatus.isRejected || editCommentStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          editCommentError?.response?.data?.message ??
          editCommentData?.response?.data?.message ??
          "Failed to edit comment. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [editCommentData, editCommentError, editCommentStatus]);

  // Delete comment response
  useMemo(() => {
    if (deleteCommentData?.status === 200) {
      runCommentsFetch(getApplicationCommentsServices({ id: deleteCommentData?.data?.data?.applicationID }));
    } else if (deleteCommentStatus.isRejected || deleteCommentStatus.isResolved) {
      setToast({
        show: true,
        heading: "Sorry",
        text:
          deleteCommentError?.response?.data?.message ??
          deleteCommentData?.response?.data?.message ??
          "Failed to delete comment. Please try again later.",
        type: false,
      });
      setTimeout(() => {
        setToast({
          ...toast,
          show: false,
        });
      }, 5000);
    }
  }, [deleteCommentData, deleteCommentError, deleteCommentStatus]);

  // Update application status response
  useMemo(() => {
    if (updateStatusData) {
      if (updateStatusData.status === 200) {
        fetchPayable();
        fetchPaid();
      } else {
        setToast({
          show: true,
          heading: "Sorry",
          text: getErrorMessage(
            updateAppStatusError ?? updateStatusData,
            "Failed to update commission status. Please try again later.",
          ),
          type: false,
        });
      }
    }
  }, [updateStatusData, updateAppStatusError, updateAppStatusStatus]);

  const viewApplicationInfo = (app: applicationInfo) => {
    // setUnreadCommentCount(unreadComments);
    runCommentsFetch(getApplicationCommentsServices({ id: app.id }));

    setViewApplication({
      show: true,
      application: app,
    });
  };

  const createComment = (id, data) => {
    runCreateComment(createCommentService({ id, data }));
  };

  const editComment = (id, commentID, data) => {
    runEditComment(editCommentService({ id, commentID, data }));
  };

  const deleteComment = (id, commentID) => {
    runDeleteComment(deleteCommentService({ id, commentID }));
  };

  const updateAppStatus = (id, status: string) => {
    const data = { status };
    runUpdateStatus(updateApplicationService({ id, data }));
  };

  const showPreloader = paidStatus.isPending || payableStatus.isPending || updateAppStatusStatus.isPending;

  const showCommentPreloader =
    createCommentStatus.isPending ||
    editCommentStatus.isPending ||
    deleteCommentStatus.isPending ||
    commentStatus.isPending;

  const [initAppInfo, setInitAppInfo] = React.useState<ApplicationData>({
    university: initialOptionType,
    uniCountry: initialOptionType,
    degree: initialOptionType,
    preferredCourse: initialOptionType,
    intakeYear: initialOptionType,
    intakeMonth: initialOptionType,
    agent: initialOptionType,
    source: initialOptionType,
    prospect: initialOptionType,
  });

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast
        show={toast.show}
        closeModal={() => setToast({ ...toast, show: false })}
        heading={toast.heading}
        text={toast.text}
        type={toast.type}
      />

      <ApplicationDetails
        app={viewApplication.application}
        comments={preformattedComments}
        show={viewApplication.show}
        closeModal={() => setViewApplication({ show: false, application: initialState })}
        editComment={(id, commentID, data) => editComment(id, commentID, data)}
        addNewComment={(id, data) => createComment(id, data)}
        deleteComment={(id, commentID) => deleteComment(id, commentID)}
        commentLoader={showCommentPreloader}
        clearComment={clearComment}
      />
      <CommissionsUI
        paidList={paidList}
        payableList={payableList}
        handleViewApplication={(data) => viewApplicationInfo(data)}
        updateStatus={updateAppStatus}
        handleSearchPayable={(x) => setSearch({ ...search, payable: x })}
        handleSearchPaid={(x) => setSearch({ ...search, paid: x })}
        sourceFilter={{ value: filter.source, control: (x) => setFilter({ ...filter, source: x }) }}
        agentFilter={{ value: filter.agent, control: (x) => setFilter({ ...filter, agent: x }) }}
        intakeMonth={{ value: filter.intakeMonth, control: (x) => setFilter({ ...filter, intakeMonth: x }) }}
        intakeYear={{ value: filter.intakeYear, control: (x) => setFilter({ ...filter, intakeYear: x }) }}
        universityFilter={{ value: filter.university, control: (x) => setFilter({ ...filter, university: x }) }}
        agentList={allAgents}
        uniList={allUnis}
        pageInfo={{
          payable: {
            count: payableData?.data?.data?.count ?? 0,
            totalPages: payableData?.data?.data?.availablePages ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, payable: x }),
            currentPage: payableData?.data?.data?.pageNo ?? 1,
          },
          paid: {
            count: paidData?.data?.data?.count ?? 0,
            totalPages: paidData?.data?.data?.availablePages ?? 1,
            updateCurrentPage: (x) => setCurrentPage({ ...currentPage, paid: x }),
            currentPage: paidData?.data?.data?.pageNo ?? 1,
          },
        }}
      />
    </>
  );
};

export { Commissions };

import { DropDownItem, Pagination, ProspectsTableBodyItem, ProspectsUI, Toast } from "components";
import React, { useMemo } from "react";
import { useApiRequest } from "hooks";
import Preloader from "components/Preloader";
import { getNationality } from "Helper/country";
import { Routes } from "routes/utils";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "Helper/errorMessage";
import { fetchAgentsUnpaginatedService, fetchProspectsService } from "services";
import { avatarImg } from "assets";
import { optionType } from "types";
import { sortAlphabetically } from "Helper/sort";
import config from "config.json";

const Prospects: React.FC = () => {
  const navigate = useNavigate();

  const [search, setSearch] = React.useState<string>("");
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(1);
  const [count, setCount] = React.useState<number>(0);
  const [planFilter, setPlanFilter] = React.useState<optionType>({ label: "All", value: "" });
  const [statusFilter, setStatusFilter] = React.useState<optionType>({ label: "All", value: "" });
  const [sourceFilter, setSourceFilter] = React.useState<optionType>({ label: "Vobb", value: "vobb" });
  const [agentFilter, setAgentFilter] = React.useState<optionType>({ label: "All", value: "" });
  const [toast, setToast] = React.useState({
    show: false,
    heading: "",
    text: "",
    type: false,
  });

  const {
    run: runListProspects,
    data: listProspectsResponse,
    requestStatus: listProspectsStatus,
    error: listProspectsError,
  } = useApiRequest({});

  const {
    run: runAllAgents,
    data: allAgentsResponse,
    requestStatus: allAgentsStatus,
    error: allAgentsError,
  } = useApiRequest({});

  const fetchProspects = () =>
    runListProspects(
      fetchProspectsService({
        pageNo: `${currentPage}`,
        search,
        plan: planFilter.value,
        agent: agentFilter.value,
        status: statusFilter.value,
        source: config.whitelabel ? config.source : sourceFilter.value,
      }),
    );

  React.useEffect(() => {
    fetchProspects();
  }, [currentPage, search, planFilter, agentFilter, statusFilter, sourceFilter]);

  const handleSearch = (search) => {
    setCurrentPage(1);
    setSearch(search);
  };

  const fetchAllAgents = () => {
    runAllAgents(fetchAgentsUnpaginatedService());
  };

  React.useEffect(() => {
    fetchAllAgents();
  }, []);

  const getStatus = (stat) => stat.replaceAll("_", " ");
  const getPlan = (plan) => {
    let planString = "";
    if (plan === "free") planString = "Sapphire (free)";
    else if (plan === "gold") planString = "Gold ($65)";
    else if (plan === "ruby") planString = "Ruby ($95)";
    else if (plan === "enterprise") planString = "Platinum (enterprise)";
    else planString = plan;

    return planString;
  };

  const checkValue = (val) => val && val !== "";

  const prospectList = useMemo<ProspectsTableBodyItem[]>(() => {
    if (listProspectsResponse && listProspectsResponse?.status === 200) {
      const data = listProspectsResponse?.data?.data;
      setCount(data?.count);
      setTotalPages(data?.availablePages);

      return data?.fetchedData.map((item) => ({
        id: item._id,
        agent: {
          name: item.agent_Info.agencyName,
          avatar: checkValue(item.agent_Info.avatar) ? item.agent_Info.avatar : avatarImg,
        },
        avatar: checkValue(item.suppDocs.passId.url) ? item.suppDocs.passId.url : avatarImg,
        name: `${item.firstName} ${item.lastName}`,
        plan: getPlan(item.paymentPlan),
        status: getStatus(item.status),
        nationality: getNationality(item.nationality),
        commentCount: item?.adminCommentInfo?.commentCount,
        dateCreated: new Date(item.createdAt).toLocaleDateString(),
      }));
    } else if (listProspectsError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(listProspectsResponse, "Unable to fetch prospects"),
        type: false,
      });
    }

    return [];
  }, [listProspectsResponse, listProspectsError]);

  const agentOptions = useMemo<optionType[]>(() => {
    if (allAgentsResponse && allAgentsResponse?.status === 200) {
      return allAgentsResponse?.data?.data?.map((item) => ({
        label: item.agencyName,
        value: item._id,
      }));
    } else if (allAgentsError) {
      setToast({
        show: true,
        heading: "Sorry",
        text: getErrorMessage(allAgentsResponse, "Unable to fetch agents"),
        type: false,
      });
    }

    return [];
  }, [allAgentsResponse, allAgentsError]);

  const showPreloader = listProspectsStatus.isPending;

  return (
    <>
      {showPreloader && <Preloader />}
      <Toast closeModal={() => setToast({ ...toast, show: false })} {...toast} />
      <ProspectsUI
        tableBodyItems={prospectList}
        handleViewProspect={(id) => {
          window.open(Routes.prospectID(id), "_blank");
        }}
        handleViewComments={(id) => {
          window.open(`${Routes.prospectID(id)}?comments=true`, "_blank");
        }}
        count={count}
        handleSearch={handleSearch}
        planFilter={{ value: planFilter, control: (x) => setPlanFilter(x) }}
        statusFilter={{ value: statusFilter, control: (x) => setStatusFilter(x) }}
        agentFilter={{ value: agentFilter, control: (x) => setAgentFilter(x) }}
        sourceFilter={{ value: sourceFilter, control: (x) => setSourceFilter(x) }}
        currentPage={currentPage}
        agentOptions={sortAlphabetically(agentOptions)}
        handleEditProspect={(id) => {
          navigate(Routes.editProspectID(id));
        }}
      />
      <Pagination currentPage={currentPage} pages={totalPages} handlePage={(x) => setCurrentPage(x)} />
    </>
  );
};

export { Prospects };

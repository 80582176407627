import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  FilterBox,
  PageHeader,
  UnVerifiedAgentsTableBody,
} from "components";
import { VerifiedAgentsErrorFallBack } from "components/ErrorFallbackUI";
import Preloader from "components/Preloader";
import { TableHeaderItemProps } from "components/table/components";
import { EmptyTableBody } from "components/VerifiedAgents/components";
import React from "react";
import {
  UnVerifiedAgencyItem,
  UnVerifiedAgentsDropdownActions,
} from "../table/tableBodyVariants/UnVerifiedAgentsTable/index";
import styles from "./styles.module.css";
import { SearchIcon } from "assets";
import { sourceOptions } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

// UI Props
interface IProps {
  preformattedAgentsData: UnVerifiedAgencyItem[];
  showPreloader: boolean;
  showErrorFallback: boolean;
  agentsFetchrequestError: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agentVerifyError: any;
  rerunfetchAgents: () => void;
  tableHeaderTitles: TableHeaderItemProps[];
  totalAgentsCount: number;
  dropdownActions: UnVerifiedAgentsDropdownActions;
  currentPage: number;
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  handleSearch: (x: string) => void;
}

// UI meant for the unverified agents page
const UnVerifiedAgentsUI: React.FC<IProps> = (props) => {
  const {
    preformattedAgentsData,
    showPreloader,
    showErrorFallback,
    agentsFetchrequestError,
    agentVerifyError,
    rerunfetchAgents,
    tableHeaderTitles,
    totalAgentsCount,
    dropdownActions,
    currentPage,
    sourceFilter,
    handleSearch,
  } = props;

  const [search, setSearch] = React.useState("");

  // All custom Table Classes
  const customTableClasses: AllCustomTableClasses = {
    tableWrapperClass: styles.tableWrapperClass,
    tableContainerClassName: styles.tableContainerClass,
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  return (
    <React.Fragment>
      <PageHeader
        title="Unverified Agents"
        description="The table below represents a list of agents that have not verified their email addresses after signup."
        count={totalAgentsCount}
      />
      <div className={styles.searchFilterWrapper}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by email, agency or representative name"
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSearch(search);
            }}
          />
        </div>
        <div className={styles.filterContainer}>
          {!config.whitelabel ? (
            <FilterBox
              onChange={(x) => sourceFilter.control(x)}
              validatorMessage={""}
              inputClass={styles.select}
              name={"source"}
              placeholder={"Select..."}
              label={"Source"}
              options={[{ label: "All", value: "" }, ...sourceOptions]}
              value={sourceFilter.value}
              parentClassName={`${styles.dropdownWrap}`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {showPreloader ? (
        <Preloader />
      ) : showErrorFallback ? (
        <VerifiedAgentsErrorFallBack
          agentsFetchrequestError={agentsFetchrequestError}
          deleteRequestError={agentVerifyError}
          rerunfetchAgents={rerunfetchAgents}
          retryDelete={dropdownActions.handleAgentVerification}
          showDeleteButtonGroup={!!agentVerifyError?.message}
          handlePageReload={() => window.location.reload()}
        />
      ) : (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={{ ...customTableClasses }} // custom class object
          EmptyTableElement={<EmptyTableBody />} // UI for empty table body
          showEmptyTableElement={preformattedAgentsData?.length < 1} //Check to show an empty agents body
          AdminTableBody={
            <UnVerifiedAgentsTableBody
              tableBodyItems={preformattedAgentsData}
              dropdownActions={dropdownActions}
              tableBodyClassName={styles.tableBodyClass}
              currentPage={currentPage}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export { UnVerifiedAgentsUI };

import { SearchIcon } from "assets/vectors";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  PageHeader,
  applicationInfo,
  CommissionsTableBody,
  CommissionsTableBodyItem,
  Pagination,
  FilterBox,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import styles from "./styles.module.css";
import { months, sourceOptions } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

interface ApplicationsProps {
  payableList: CommissionsTableBodyItem[];
  paidList: CommissionsTableBodyItem[];
  handleViewApplication: (app: applicationInfo) => void;
  updateStatus: (id, status: string) => void;
  handleSearchPayable: (x: string) => void;
  handleSearchPaid: (x: string) => void;
  universityFilter: {
    value: optionType;
    control: (x) => void;
  };
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  agentFilter: {
    value: optionType;
    control: (x) => void;
  };
  intakeYear: {
    value: optionType;
    control: (x) => void;
  };
  intakeMonth: {
    value: optionType;
    control: (x) => void;
  };
  agentList: optionType[];
  uniList: optionType[];
  pageInfo: {
    payable: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
    paid: {
      count: number;
      totalPages: number;
      updateCurrentPage: (x) => void;
      currentPage: number;
    };
  };
}

const CommissionsUI: React.FC<ApplicationsProps> = ({
  handleViewApplication,
  updateStatus,
  universityFilter,
  agentFilter,
  agentList,
  sourceFilter,
  uniList,
  pageInfo,
  paidList,
  payableList,
  handleSearchPaid,
  handleSearchPayable,
  intakeMonth,
  intakeYear,
}) => {
  const [search, setSearch] = React.useState("");
  const [view, setView] = React.useState<number>(1);

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Prospect/Agency" },
    { title: "Program" },
    { title: "Commission" },
    { title: "Status" },
    { title: "Date Created" },
    { title: "" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      view === 1 ? handleSearchPayable("") : handleSearchPaid("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  const selectBoxStyles = {
    background: "transparent",
    color: "inherit",
    fontWeight: "500",
    minWidth: "unset",
    border: "none",
    padding: "8px 10px",
    borderRadius: "8px",
    fontSize: "13px",
    paddingRight: "2rem",
    justifyContent: "flex-end",
  };

  React.useEffect(() => {
    agentFilter.control("");
    sourceFilter.control("");
    universityFilter.control("");
  }, [view]);

  const oneInstitution = config.whitelabel && config.institution?.enabled;

  const year = new Date().getFullYear();
  const years = Array.from(new Array(year - 2020 + 20), (_, index) => index + 2020);
  const yearOptions: optionType[] = years.map((item) => ({
    value: String(item),
    label: String(item),
  }));

  return (
    <section>
      <PageHeader
        title="Commissions"
        description={`The table below presents a list of commissions processed on ${config.name}`}
      />
      <nav className={styles.nav}>
        <p className={view === 1 ? styles.activeNav : ""} role={"button"} onClick={() => setView(1)}>
          <span>{pageInfo.payable.count}</span>
          Payable
        </p>
        <p className={view === 2 ? styles.activeNav : ""} role={"button"} onClick={() => setView(2)}>
          <span>{pageInfo.paid.count}</span>
          Paid
        </p>
      </nav>
      <div className={styles.actionWrap}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by student name"
            onKeyPress={(e) => {
              if (e.key === "Enter") view === 1 ? handleSearchPayable(search) : handleSearchPaid(search);
            }}
          />
        </div>
        {/* <button className={styles.reloadBtn}>Export</button> */}
      </div>
      <div className={styles.filterWrapper}>
        <FilterBox
          onChange={(x) => intakeYear.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Intake Year"}
          options={[{ label: "All", value: "" }, ...yearOptions]}
          value={intakeYear.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        <FilterBox
          onChange={(x) => intakeMonth.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Intake Month"}
          options={[{ label: "All", value: "" }, ...months]}
          value={intakeMonth.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        {!config.whitelabel ? (
          <FilterBox
            onChange={(x) => sourceFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"source"}
            placeholder={"Select..."}
            label={"Source"}
            options={[{ label: "All", value: "" }, ...sourceOptions]}
            value={sourceFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
        ) : (
          ""
        )}
        <FilterBox
          onChange={(x) => agentFilter.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Agent"}
          options={[{ label: "All", value: "" }, ...agentList]}
          value={agentFilter.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        {oneInstitution ? (
          ""
        ) : (
          <FilterBox
            onChange={(x) => universityFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"university"}
            placeholder={"Select..."}
            label={"Institution"}
            options={[{ label: "All", value: "" }, ...uniList]}
            value={universityFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
        )}
      </div>

      {view === 1 ? (
        <>
          {payableList.length > 0 ? (
            <>
              <AdminDashBoardTable
                tableHeaderTitles={tableHeaderTitles}
                customTableClasses={CustomTableClasses}
                AdminTableBody={
                  <CommissionsTableBody
                    viewComment={handleViewApplication}
                    tableBodyClassName={styles.tableBodyClass}
                    tableBodyItems={payableList}
                    updateStatus={updateStatus}
                    currentPage={pageInfo.payable.currentPage}
                  />
                }
              />
              <Pagination
                currentPage={pageInfo.payable.currentPage}
                pages={pageInfo.payable.totalPages}
                handlePage={pageInfo.payable.updateCurrentPage}
              />
            </>
          ) : search ? (
            <div className={styles.noresults}>Sorry, there are no results for your search for {search}</div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {paidList.length > 0 ? (
            <>
              <AdminDashBoardTable
                tableHeaderTitles={tableHeaderTitles}
                customTableClasses={CustomTableClasses}
                AdminTableBody={
                  <CommissionsTableBody
                    viewComment={handleViewApplication}
                    tableBodyClassName={styles.tableBodyClass}
                    tableBodyItems={paidList}
                    currentPage={pageInfo.paid.currentPage}
                  />
                }
              />
              <Pagination
                currentPage={pageInfo.paid.currentPage}
                pages={pageInfo.paid.totalPages}
                handlePage={pageInfo.paid.updateCurrentPage}
              />
            </>
          ) : search ? (
            <div className={styles.noresults}>Sorry, there are no results for your search for {search}</div>
          ) : (
            ""
          )}
        </>
      )}
    </section>
  );
};

export { CommissionsUI };

import { AddIcon, SearchIcon } from "assets/vectors";
import {
  AdminDashBoardTable,
  AllCustomTableClasses,
  PageHeader,
  ApplicationsTableBody,
  ApplicationsTableBodyItem,
  applicationInfo,
  SelectBox,
  DropDownItem,
  FilterBox,
} from "components";
import { TableHeaderItemProps } from "components/table/components";
import React from "react";
import styles from "./styles.module.css";
import { CreateApplicationUI } from "./createApplication";
import { ApplicationData, EditApplicationUI } from "./editApplication";
import { months, sourceOptions, sourceOptionsFilter } from "Helper/options";
import { optionType } from "types";
import config from "config.json";

interface ApplicationsProps {
  tableBodyItems: ApplicationsTableBodyItem[];
  handleViewApplication: (app: applicationInfo) => void;
  count: number;
  // statusFilter: (status: string) => void;
  reload: () => void;
  updateStatus: (id, status: string) => void;
  currentPage: number;
  startSearch: (x: string) => void;
  universityFilter: {
    value: optionType;
    control: (x) => void;
  };
  statusFilter: {
    value: optionType;
    control: (x) => void;
  };
  sourceFilter: {
    value: optionType;
    control: (x) => void;
  };
  agentFilter: {
    value: optionType;
    control: (x) => void;
  };
  countryFilter: {
    value: optionType;
    control: (x) => void;
  };
  intakeYear: {
    value: optionType;
    control: (x) => void;
  };
  intakeMonth: {
    value: optionType;
    control: (x) => void;
  };
  agentList: optionType[];
  uniList: optionType[];
  countryList: optionType[];
  createApplication: () => void;
  editApplication: (app: ApplicationData, id: string) => void;
  viewProspect: (id: string) => void;
}

export const applicationStatus = (status) => {
  return <span className={`${styles.wrapStatus} ${styles[status?.toLowerCase()]}`}>{status}</span>;
};

const ApplicationsUI: React.FC<ApplicationsProps> = ({
  tableBodyItems,
  handleViewApplication,
  count,
  statusFilter,
  reload,
  updateStatus,
  currentPage,
  startSearch,
  universityFilter,
  agentFilter,
  countryFilter,
  agentList,
  sourceFilter,
  uniList,
  countryList,
  createApplication,
  editApplication,
  viewProspect,
  intakeMonth,
  intakeYear,
}) => {
  const [search, setSearch] = React.useState("");

  // This  is to show how to use the table component
  const tableHeaderTitles: TableHeaderItemProps[] = [
    { title: "S/N" },
    { title: "Prospect/Agency" },
    { title: "University" },
    { title: "Program" },
    { title: "Status" },
    { title: "Date Created" },
    { title: "" },
  ];

  // Custom table Classes
  const CustomTableClasses: AllCustomTableClasses = {
    tableHeaderClassName: styles.tableHeaderClass,
    tableHeaderItemClassName: styles.tableHeaderItem,
    tableWrapperClass: styles.tableWrapperClass,
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      startSearch("");
    }
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch("");
  }, []);

  const statusOptions: optionType[] = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Sent",
      value: "sent",
    },
    {
      label: "Submitted",
      value: "submitted",
    },
    {
      label: "Admission",
      value: "admission",
    },
    {
      label: "Withdrawn",
      value: "withdrawn",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Enrolled",
      value: "enrolled",
    },
    {
      label: "Visa Denied",
      value: "visaDenied",
    },
    {
      label: "Payable",
      value: "payable",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ];

  const oneInstitution = config.whitelabel && config.institution?.enabled;

  const year = new Date().getFullYear();
  const years = Array.from(new Array(year - 2020 + 20), (_, index) => index + 2020);
  const yearOptions: optionType[] = years.map((item) => ({
    value: String(item),
    label: String(item),
  }));

  return (
    <section>
      <PageHeader
        title="Applications"
        description={`The table below presents a list of applications processed on ${config.name}. Click the view button to see the applicant's details.`}
        count={count}
      />
      <button onClick={() => createApplication()} className={styles.createBtn}>
        <AddIcon /> Create Application
      </button>
      <div className={styles.actionWrap}>
        <div className={styles.searchInputWrapper}>
          <SearchIcon />
          <input
            className={styles.searchInput}
            value={search}
            onChange={handleChange}
            type="search"
            placeholder="Search by student name"
            onKeyPress={(e) => {
              if (e.key === "Enter") startSearch(search);
            }}
          />
        </div>

        <button onClick={reload} className={styles.reloadBtn}>
          Reload
        </button>
      </div>
      <div className={styles.filterWrapper}>
        <FilterBox
          onChange={(x) => intakeYear.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Intake Year"}
          options={[{ label: "All", value: "" }, ...yearOptions]}
          value={intakeYear.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        <FilterBox
          onChange={(x) => intakeMonth.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Intake Month"}
          options={[{ label: "All", value: "" }, ...months]}
          value={intakeMonth.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        {!config.whitelabel ? (
          <FilterBox
            onChange={(x) => sourceFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"source"}
            placeholder={"Select..."}
            label={"Source"}
            options={[{ label: "All", value: "" }, ...sourceOptions]}
            value={sourceFilter.value}
            parentClassName={`${styles.dropdownWrap} ${styles.leftMargin}`}
          />
        ) : (
          ""
        )}
        <FilterBox
          onChange={(x) => statusFilter.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"status"}
          placeholder={"Select..."}
          label={"Status"}
          options={statusOptions}
          value={statusFilter.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        <FilterBox
          onChange={(x) => agentFilter.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"agent"}
          placeholder={"Select..."}
          label={"Agent"}
          options={[{ label: "All", value: "" }, ...agentList]}
          value={agentFilter.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
        {!oneInstitution && (
          <FilterBox
            onChange={(x) => universityFilter.control(x)}
            validatorMessage={""}
            inputClass={styles.select}
            name={"university"}
            placeholder={"Select..."}
            label={"Institution"}
            options={[{ label: "All", value: "" }, ...uniList]}
            value={universityFilter.value}
            parentClassName={`${styles.dropdownWrap}`}
          />
        )}
        <FilterBox
          onChange={(x) => countryFilter.control(x)}
          validatorMessage={""}
          inputClass={styles.select}
          name={"country"}
          placeholder={"Select..."}
          label={"Country"}
          options={[{ label: "All", value: "" }, ...countryList]}
          value={countryFilter.value}
          parentClassName={`${styles.dropdownWrap}`}
        />
      </div>

      {tableBodyItems.length > 0 ? (
        <AdminDashBoardTable
          tableHeaderTitles={tableHeaderTitles}
          customTableClasses={CustomTableClasses}
          AdminTableBody={
            <ApplicationsTableBody
              viewComment={handleViewApplication}
              tableBodyClassName={styles.tableBodyClass}
              tableBodyItems={tableBodyItems}
              updateStatus={updateStatus}
              currentPage={currentPage}
              editApplication={editApplication}
              viewProspect={viewProspect}
            />
          }
        />
      ) : search ? (
        <div className={styles.noresults}>Sorry, there are no results for your search for {search}</div>
      ) : (
        ""
      )}
    </section>
  );
};

export { ApplicationsUI, CreateApplicationUI, EditApplicationUI };
export * from "./editApplication";
export * from "./createApplication";
